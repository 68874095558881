import React from 'react';
import { Grid, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    footer: {
        borderTop: '1px solid #e6e9eb',
    },
    socialIcon: {
        height: '28px'
    },
    socialLink: {
        color: 'white',
        fontWeight: 'bold',
        '&:hover': {
            color: 'white'
        }
    },
    socialContainer: {
        padding: '0.5rem 0.5rem',
    },
    contactContainer: {
        width: '100%'
    },
    footerGrid: {
        display:'flex',
        padding: '0rem 1rem',
        justifyContent: 'left'
    }
}))

function Footer({style}) {
    const classes = useStyles()
    return <Grid container justify='center'  style={style}>
        <Grid item  md={2}></Grid>
        <Grid item  md={4} className={classes.footerGrid} style={{paddingTop: 10}}>
            <Grid container justify='center'>
                <Grid item md={12} className={classes.contactContainer}>
                    <p><strong style={{fontWeight: 'bold'}}>Espai La Roda:</strong> Passatge Serra i
                    Arola, 15 (08028 Barcelona)</p>
                </Grid>
                <Grid item md={12} className={classes.contactContainer}>
                    <p><strong style={{fontWeight: 'bold'}}>Teléfono: </strong>(+34) 675 20 24 87</p>
                </Grid>
                <Grid item md={12} className={classes.contactContainer}>
                    <p><strong style={{fontWeight: 'bold'}}>Email: </strong>eslaroda@gmail.com</p>
                </Grid>
            </Grid>
        </Grid>
        <Grid item  md={4} className={classes.footerGrid} style={{paddingTop: 10}}>
            <Grid container alignContent="flex-start">
                <Grid item md={12} className={classes.socialContainer}>
                    <a
                    href="https://www.instagram.com/espailaroda"
                    className={classes.socialLink}
                    target="_blank">
                        <img className={classes.socialIcon} src="instagram.svg"></img> Instagram
                    </a>
                </Grid>
                <Grid item md={12} className={classes.socialContainer}>
                    <a
                    href="https://www.facebook.com/EspaiLaRoda"
                    className={classes.socialLink}
                    target="_blank">
                        <img className={classes.socialIcon} src="facebook.svg"></img> Facebook
                    </a>
                </Grid>
            </Grid>
        </Grid>
        <Grid item  md={2}></Grid>
    </Grid>
}

export default Footer