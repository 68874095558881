import React, {useState} from 'react'
import Lightbox from 'react-image-lightbox'
import { Grid, Card, makeStyles, 
         CardContent, Typography, CardMedia, CardActionArea,
         } from '@material-ui/core'
import 'react-image-lightbox/style.css'

const useStyles = makeStyles({
  card: {
    // maxWidth: 345,
    marginBottom: '2em',
    display: 'flex',
    boxShadow: 'none',
    width: '18em'
  },
  media: {
    // height: '21em',
  },
  mainGrid: {
    //   padding: '3em 10% 0em 10%',
      paddingTop: '3em',
      flexGrow: 1,
      maxWidth: '100%'
  },
  presupuestoText: {
      textAlign: "center",
      marginBottom: "1em"
  },
  presupuestoLink: {
      color: "black",
      textDecoration: "underline"
  },
  gallery: {
      height: '2rem',
      position: 'absolute',
      right: '5px',
      top: '5px',
  },
}); 

const images = [
  'roda1.jpg',
  'roda2.jpg',
  'roda3.jpg',
  'roda4.jpeg',
];
const images2 = [
    'salaPequeña.jpg',
  'salaPequeña2.jpg',
];

function Rent() {
    const [open, setOpen] = useState(false)
    const [open2, setOpen2] = useState(false)
    const [indexMain, setIndexMain] = useState(0)
    const width = window.innerWidth
    const big = width>=640
    const classes = useStyles();
    return <Grid container className={classes.mainGrid} spacing={big?2:0} justify="center">
        <Grid item sm={12} className={classes.presupuestoText}>
            <Typography component="h6" style={{fontSize: 17}}>Alquilamos nuestras salas para actividades regulares o puntuales,
            durante la semana y fines de semana.<br></br><a className={classes.presupuestoLink} href="/contact">Pide un presupuesto.</a></Typography>
        </Grid>
        <Grid item lg={4} sm={12}>
            <Card className={big?undefined:classes.card}>
                <CardActionArea>
                    <CardMedia
                        className={classes.media}
                        image="roda1.jpg"
                        title="Sala 1"
                        style={{height: big? '21em':'11em'}}
                        onClick={()=>{setOpen(true)}}
                    >
                        <img className={classes.gallery} src="image-gallery.svg" alt="" />
                    </CardMedia>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            Sala Amazonas
                        </Typography>
                        <ul>
                            <li>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    63m<sup>2</sup>
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    Suelo de madera
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    Espejos
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    Luz natural
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    Aire acondicionado
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body2" color="textSecondary" component="p">
                                WiFi
                                </Typography>
                            </li>
                        </ul>
                    </CardContent>
                </CardActionArea>
            </Card>
            {open && (
                <Lightbox
                    mainSrc={images[indexMain]}
                    nextSrc={images[(indexMain + 1) % images.length]}
                    prevSrc={images[(indexMain + images.length - 1) % images.length]}
                    onCloseRequest={() => setOpen(false)}
                    onMovePrevRequest={() =>
                        setIndexMain((indexMain + images.length - 1) % images.length)
                    }
                    onMoveNextRequest={() =>
                        setIndexMain((indexMain + 1) % images.length)
                    }
                />
            )}
        </Grid>
        <Grid item lg={4} sm={12}>
            <Card className={big?undefined:classes.card}>
                <CardActionArea>
                    <CardMedia
                        className={classes.media}
                        // image={big?"salaPequeña.jpg": "salaPequeñaTlf.jpg"}
                        image={"salaPequeña.jpg"}
                        title="Sala 1"
                        style={{height: big? '21em':'11em'}}
                        onClick={()=>{setOpen2(true)}}
                    >
                        <img className={classes.gallery} src="image-gallery.svg" alt="" />
                    </CardMedia>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            Sala Manaus
                        </Typography>
                        <ul>
                            <li>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    35m<sup>2</sup>
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    Suelo de madera
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body2" color="textSecondary" component="p">
                                WiFi
                                </Typography>
                            </li>
                        </ul>
                    </CardContent>
                </CardActionArea>
            </Card>
            {open2 && (
                <Lightbox
                    mainSrc={images2[indexMain]}
                    nextSrc={images2[(indexMain + 1) % images2.length]}
                    prevSrc={images2[(indexMain + images2.length - 1) % images2.length]}
                    onCloseRequest={() => setOpen2(false)}
                    onMovePrevRequest={() =>
                        setIndexMain((indexMain + images2.length - 1) % images2.length)
                    }
                    onMoveNextRequest={() =>
                        setIndexMain((indexMain + 1) % images2.length)
                    }
                />
            )}
        </Grid>
    </Grid>
}

export default Rent