import React from "react";
import HeaderTittle from "./HeaderTittle/HeaderTittle.jsx";
import Footer from "./Footer/footer.jsx";
import "./App.css";
import { Grid, makeStyles } from "@material-ui/core";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Home from "./Body/home";
import Contact from "./Body/contact";
import Activities from "./Body/activities";
import NavBar from "./Navbar/navBar"
import Rent from "./Body/rent";

const useStyles = makeStyles(theme => ({
  link: {
    display: "block",
    color: "#858081",
    padding: "1em .95em",
    paddingBottom: "0.5em",
    fontSize: "19px",
    "&:hover": {
      color: "#415161",
      textDecoration: "none"
    },
    "font-family": "Crimson Text"
  }
}));
function App() {
  const classes = useStyles();
  // if (view === 'contact' || view === 'activities') {
  //   styleFooter = {
  //       position: 'fixed',
  //       bottom: '0px'
  //   }
  // }
  return (
    <div>
      <div className="page-wrap">
        <Router>
          <HeaderTittle/>
          <NavBar></NavBar>
          <Switch>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/activities">
              <Activities />
            </Route>
            <Route path="/rent">
              <Rent />
            </Route>
            <Route>
              <Home />
            </Route>
          </Switch>
        </Router>
        {/* <Body view={view}></Body> */}
      </div>
      <div className="site-footer">
        <Footer></Footer>
      </div>
    </div>
  );
}

export default App;
