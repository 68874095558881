import React from 'react';
import { Grid, makeStyles, Menu, MenuItem, IconButton, Button   } from '@material-ui/core'
import { Link } from "react-router-dom";
import { Linking } from 'react-native'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Menu as MenuIcon} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    link: {
        display: 'block',
        color: '#666666',
        padding: '0.5em 1.3em',
        paddingBottom: '0.5em',
        fontSize: '17px',
        "&:hover": { 
            color: '#415161',
            textDecoration: 'none'
        }
    },
    linkTlf: {
        display: 'block',
        color: '#666666',
        margin: 'auto',
        marginTop: '0px',
        marginBottom: '0px',
        fontSize: '15px',
        "&:hover": { 
            color: '#415161',
            textDecoration: 'none'
        }
    },
    menuItem: {
      paddingTop: '0px',
      paddingBottom: '0px',
      minHeight: '42px'
    },
    menuButton: {
      color: "#9C1116",
      border: "1px solid #9C1116 ",
      size: '2em',
      borderRadius: "10%",
      float: "right",
      marginRight: "10px",
      marginBottom: "5px"
    },
    popoverPaper: {
      width: '100%',
      left: '0px !important',
      top: '0px !important',
      // height: '100%',
      maxHeight: 'unset',
      maxWidth: 'unset',
    },
    bottonContact: {
        marginLeft: '2em',
        marginBottom: '5px'
    }
}))

function NavBar(){

    const classes = useStyles();
    const matches = useMediaQuery('(min-width:800px)')
    const [anchorEl, setAnchorEl] = React.useState(null)

    if (matches) {
      return <div><Grid 
          container
          justify='center'
          style={{marginBottom: '5px',marginTop: '0px'}}
        >
          <Link to="/home" className={classes.link} >
            INICIO
          </Link>
          <Link to="rent" className={classes.link}>
              ALQUILER SALAS
          </Link>
          <Link to="activities" className={classes.link}>
              ACTIVIDADES
          </Link>
          <Link to="contact" className={classes.link}>
              CONTACTO
          </Link>
              <Button
                  style={{
                      border: '2px solid #9C1116',
                      color: '#9C1116',
                      fontSize: '15px',
                      borderRadius: '10px',
                      fontWeight: 'bold',
                      margin: '3px',
                      position: 'absolute',
                      right: '8%'
                  }}
                  className={classes.bottonContact}
                  variant="outlined"
                  color="secondary"
                  onClick={() => Linking.openURL('https://wa.me/34675202487')}
              ><img src="red-whatsapp-50.png" alt="" style={{marginRight: '1em'}}></img>Contacta</Button>
        </Grid>
        </div>
    } else {
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }
  return <div>
      <IconButton  
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.menuButton}
      >
        <MenuIcon fontSize="medium"/>
      </IconButton >
      <Menu
        PopoverClasses={{paper: classes.popoverPaper}}
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{width: '100%',}}
      >
        <MenuItem onClick={handleClose} className={classes.menuItem}>       
          <Link to="/home" className={classes.linkTlf}  >
             INICIO
          </Link>
        </MenuItem>
        <MenuItem onClick={handleClose} className={classes.menuItem}>       
          <Link to="/rent" className={classes.linkTlf}  >
            ALQUILER SALAS
          </Link>
        </MenuItem>
        <MenuItem onClick={handleClose} className={classes.menuItem}>       
          <Link to="/activities" className={classes.linkTlf}  >
            ACTIVIDADES
          </Link>
        </MenuItem>
        <MenuItem onClick={handleClose} className={classes.menuItem}>       
          <Link to="/contact" className={classes.linkTlf}  >
            CONTACTO
          </Link>
        </MenuItem>
      </Menu>
    </div>
    
  }
}
  
  export default NavBar;