import React from 'react'
import { Grid } from '@material-ui/core'
import { Link } from "react-router-dom";

function HeaderTittle(){
  //TODO: Alquiler de salas(nueva seccion):
  //      1. sala Amazonas 63 m2, sala Manaos 35 m2
    const width = window.innerWidth
  return (
    <Grid container justify='center'>
      {   width >= 640 ?
      
      <Link to="/home"  >
        <img src='Logo2.png' alt='' style={{maxHeight: '15em', cursor: 'pointer'}}></img>
      </Link>
      :
      <Link to="/home"  >
        <img src='logo.jpg' alt='' style={{maxHeight: '8em', cursor: 'pointer'}}></img>
      </Link>
      }
    </Grid>
  );
}

export default HeaderTittle;
