import React from 'react';
import { Typography, Grid, makeStyles,
    Card, CardContent,CardMedia, CardActionArea,
    Button } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    card: {
      display: 'flex',
      boxShadow: 'none',
    },
    cardTlf: {
      display: 'flex',
      boxShadow: 'none',
      width: '100%'
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
      width: '25em'
    },
    content: {
      flex: '1 0 auto',
      paddingTop: '20px',
    },
    cover: {
      width: '20em',
      height: '13em'
    },
    coverTlf: {
      width: '100%',
      height: '12em'
    },
    mainContain: {
        marginTop: '20px'
    },
    timeTables: {
        marginBottom: '2px',
        color: '#9C1116',
        fontWeight: 'bold'
    },
    cardContainer: {
        borderBottom: '1px solid #e6e9eb',
        justifyContent: 'center',
        display: 'flex',
        paddingBottom: 30,
        paddingTop: 15
    },
    cardContainerTlf: {
        marginBottom: '1em',
        border: '1px solid #EEEEEE'
    },
    infoButton: {
        borderRadius: '0px',
        background: '#415161',
        alignSelf: 'center',
        marginTop: '20px',
        color: 'white'
    },
    tittleContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '30px',
    },
    activitiesTitle: {
        fontSize: 30
    }
  }));

function Activities() {
    const classes = useStyles()
    const now = new Date()
    const year = now.getFullYear()
    const activities = [
        {
            name: 'Capoeira Angola Adultos',
            organizacion: 'Matumbé capoeira',
            web: 'https://matumbecapoeira.es/',
            image: 'raboDeArraia.jpg',
            timeTables: [
                'Martes, Jueves: 11:30 - 13:00',
                'Viernes: 9:30 - 11:00',
                'Lunes, Miércoles, Viernes: 20:00 - 21:30'
            ],
            customStyle: {}
        },
        {
            name: 'Capoeira infantil (3 a 8 años)',
            organizacion: 'Matumbé capoeira',
            web: 'https://matumbecapoeira.es/',
            image: 'Infantil1.jpg',
            timeTables: ['Martes y Jueves: 17:00 - 18:00'],
            customStyle: {}
        },
        {
            name: 'Capoeira juvenil (9 a 12 años)',
            organizacion: 'Matumbé capoeira',
            web: 'https://matumbecapoeira.es/',
            image: 'juvenil.jpg',
            timeTables: ['Martes y Jueves: 18:00 - 19:00'],
            customStyle: {}
        },
        {
            name: 'Capoeira en familia',
            organizacion: 'Matumbé capoeira',
            web: 'https://matumbecapoeira.es/',
            image: 'CapoFamilia.jpeg',
            timeTables: ['Miércoles y Viernes: 17:30 - 18:30'],
            customStyle: {}
        },
        {
            name: 'Animal Movement',
            organizacion: 'Espai la Roda',
            web: 'https://espailaroda.es/contact',
            image: 'animalMovement.jpg',
            timeTables: ['Lunes y Miercoles: 11:00 - 12:00'],
            customStyle: {}
        },
        {
            name: 'Power Yoga',
            organizacion: 'Espai la Roda',
            web: 'https://espailaroda.es/contact',
            image: 'PowerYoga.jpg',
            timeTables: ['Viernes: 11:00 - 12:00'],
            customStyle: {
                width: '100%',
                height: '10em'
            }
        },
        {
            name: 'Danza Afrobrasileña',
            web: 'http://www.espailaroda.es/',
            image: 'actividadAfro.jpeg',
            timeTables: ['Martes: 20:00 - 21:00'],
            customStyle: {}
        },
        {
            name: 'Burlesque In&Out',
            organizacion: 'Empodérate BCN',
            web: 'https://empoderatebcn.com/',
            image: 'EmpoderateBCN.PNG',
            timeTables: ['Lunes: 18:00 - 19:30', 'Jueves: 18:00 - 19:30', 'Jueves (avanzados): 19:30 - 21:00'],
            customStyle: {}
        },
        {
            name: 'Bollywood',
            organizacion: 'Bollywood Sitare',
            web: 'https://www.instagram.com/bollywoodsitare/',
            image: 'bollywood.jpeg',
            timeTables: ['Miercoles 18.30-19.30h'],
            customStyle: {}
        },
        {
            name: 'Capoeira ONLINE',
            organizacion: 'Matumbé capoeira',
            web: 'https://matumbecapoeira.es/',
            image: 'raboDeArraia.jpg',
            timeTables: [
                'Miércoles, Viernes: 19:00 - 20:00'
            ],
            customStyle: {}
        },
    ]
    const width = window.innerWidth
    return <Grid container justify='center' className={classes.mainContain}>
        <Grid  item xs={12} sm={12} className={classes.tittleContainer} >
            <Typography align='center' variant="h5" className={classes.activitiesTitle}>
                Programación {year}
            </Typography>
        </Grid>
        {   width >= 640 ?
            activities.map( (acti, i) => (
                <Grid  item key={i}  md={12} className={classes.cardContainer} style={{borderBottom: 0}}>
                    <Card className={classes.card}>
                        <div className={classes.details}>
                            <CardContent className={classes.content}>
                                <Typography component="h5" variant="h5">
                                    {acti.name}
                                </Typography>
                                {acti.subName ?
                                    <Typography variant="subtitle1" color="textSecondary" style={{marginBottom: '8px'}}>
                                        {acti.subName}
                                    </Typography> : ''
                                }
                                <Typography variant="subtitle1" color="textSecondary" style={{marginBottom: '8px'}}>
                                    {acti.organizacion}
                                </Typography>
                                    {acti.timeTables.map((tTable, i) => (<p key={i} className={classes.timeTables}>{tTable}</p>))}
                                <Grid container justify='center'>
                                    <Button 
                                        variant="contained"
                                        onClick={() => window.open(acti.web, '_blank')}
                                        className={classes.infoButton}
                                    >
                                        +Info
                                    </Button>
                                </Grid>
                            </CardContent>
                        </div>
                        <CardMedia
                            className={classes.cover}
                            image={acti.image}
                            // title="Roda de capoeira"
                        />
                    </Card>
                </Grid>
            )) :
            activities.map( (acti, i) => (
                <Grid key={i} container className={classes.cardContainerTlf} justify='center' style={{width: '80%'}} >
                    <Card className={classes.cardTlf} >
                        <CardActionArea >
                            <CardMedia 
                                className={classes.coverTlf}
                                image={acti.image}
                                title="Contemplative Reptile"
                                img={classes.customStyle}

                            />
                            <CardContent className={classes.content}>
                                <Typography component="h5" variant="h5">
                                    {acti.name}
                                </Typography>
                                {acti.subName ?
                                    <Typography variant="subtitle1" color="textSecondary" style={{marginBottom: '8px'}}>
                                        {acti.subName}
                                    </Typography> : ''
                                }
                                <Typography variant="subtitle1" color="textSecondary" style={{marginBottom: '8px'}}>
                                    {acti.organizacion}
                                </Typography>
                                    {acti.timeTables.map((tTable, i) => (<p key={i} className={classes.timeTables}>{tTable}</p>))}
                                <Grid container justify='center'>
                                    <Button 
                                        variant="contained"
                                        onClick={() => window.open(acti.web, '_blank')}
                                        className={classes.infoButton}
                                    >
                                        +Info
                                    </Button>
                                </Grid>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            ))
        }
    </Grid>
}

export default Activities