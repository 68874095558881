import React from 'react'
import { Linking } from 'react-native'
import { Typography, Grid, makeStyles, Button } from '@material-ui/core'

import useMediaQuery from '@material-ui/core/useMediaQuery';


const useStyles = makeStyles(theme => ({
    gridItem: {
        padding: '1em 3em 1em 3em'
    },
    gridItemTlf: {
        padding: '1em 1em 1em 1em'
    },
    slogan: {
        'font-weight': 'bolder',
        fontStyle: 'normal',
        color: "#9C1116"
    },
    subSlogan: {
        marginTop: "1em",
    },
    linkAb: {
        marginTop: "1em",
        color: "#9C1116",
        fontWeight: "bold",
        border: '2px solid #9C1116',
        fontSize: '15px',
    },
    frontImage: {
        justifySelf: 'center',
        height: '20em',
        width: '35em',
        overflow: 'hidden'
    },
    frontImageAb: {
        justifySelf: 'center',
        // height: '20em',
        width: '35em',
        overflow: 'hidden'
    },
    frontImageMob: {
        justifySelf: 'center',
        overflow: 'hidden'
    },
    description: {
        textAlign: 'center',
        color: '#555555',
        paddingLeft: '2em',
        marginTop: '1em',
        fontFamily: 'Roboto Slab',
        fontSize: '1.2em'
    },
    descriptionContainer: {
        backgroundColor: '#9C1116',
        justifyContent: 'left'
    },
    bottonContact: {
        marginTop: '1em',
        marginLeft: '2em'
    },
    homeContainer: {
        marginTop: '1em',
    },
    subcontainer1: {
        background: "#EEEEEE",
        padding: '2em 7%'
    },
    subcontainer2: {
        background: "#F3F3F3",
        padding: '2em 7%'
    },
    subcontainer3: {
        background: "#F8F8F8",
        padding: '2em 7%'
    }
}))

function Home() {
    const classes = useStyles();
    const matches = useMediaQuery('(min-width:800px)');
    let styleDesc = {}

    return <Grid container justify='center' className={classes.homeContainer}>
        {!matches && <Grid item xs={12} className={classes.descriptionContainer} style={styleDesc}>
            <Grid container justify='center' alignItems='center'>
                <Grid  >
                    <Button
                        style={{borderColor: 'white', color: 'white', fontSize: '12px', margin: 3}}
                        className={classes.bottonContact}
                        variant="outlined"
                        color="secondary"
                        onClick={() => Linking.openURL('whatsapp://send?text=Hola&phone=+34675202487')}
                    ><img src="whatsapp-32.ico" alt="" style={{marginRight: '1em'}}></img>Solicita información</Button>
                </Grid>
            </Grid>
        </Grid>}
        <Grid item  xs={12} className={classes.subcontainer1}> 
            <Grid container>
                <Grid item  lg={2} sm={0}></Grid>
                <Grid item  className={matches? classes.gridItem: classes.gridItemTlf} lg={6} sm={12}>
                    <Typography variant='h4' className={ classes.slogan}>
                        ¿Quiénes somos?
                    </Typography>
                    <p className={classes.subSlogan}>El Centre Cultural Espai La Roda, fundado el 1 de diciembre de 2019 en Barcelona,
                     es un espacio creado con la intención de servir como medio para la difusión de distintas expresiones artísticas,
                      físicas y culturales. Su finalidad es hacer accesible la cultura para un público amplio, sobre todo en aquellas variantes 
                      de la misma que sean de menor conocimiento o poco populares. </p>
                </Grid>
                <Grid item 
                        className={matches? classes.frontImage: classes.frontImageMob} lg={4} sm={12}>
                    <img 
                        alt="Sala"
                        src='./quienesSomosGirada.jpg' 
                        style={{width: matches? '42em':'100%'}}
                    ></img>
                </Grid>
            </Grid>
        </Grid>
        <Grid item  lg={12} className={classes.subcontainer2}> 
            <Grid container>
                <Grid item  xs={2}></Grid>
                <Grid item  className={matches? classes.gridItem: classes.gridItemTlf} lg={6} sm={12}>
                    <Typography variant='h4' className={ classes.slogan}>
                        Nuestras actividades
                    </Typography>
                    <p className={classes.subSlogan}>En el Espai La Roda se ofrece la enseñanza íntegra de la Capoeira Angola y 
                    otras artes relacionadas con la cultura afrobrasileña así como danzas de otras regiones del mundo. 
                    El Espai La Roda está abierto a incorporar nuevos proyectos culturales que enriquezcan la oferta cultural en la
                     ciudad de Barcelona.</p>
                </Grid>
                <Grid item
                        className={matches? classes.frontImage: classes.frontImageMob} lg={4} sm={12}>
                        <img 
                            alt="Sala"
                            src='./actividadAfro.jpeg' 
                            style={{width: matches? '100%':'100%'}}
                        ></img>
                </Grid>
            </Grid>
        </Grid>
        <Grid item  lg={12} className={classes.subcontainer3}> 
            <Grid container>
                <Grid item  xs={2}></Grid>
                <Grid item  className={matches? classes.gridItem: classes.gridItemTlf} lg={6} sm={12}>
                    <Typography variant='h4' className={ classes.slogan}>
                        Nuestro espacio
                    </Typography>
                    <p className={classes.subSlogan}>El Centre Cultural Espai La Roda dispone de dos salas de diferentes tamaños que se adaptan a 
                    la necesidad de las actividades que ofrece. Está equipado con vestuario, dos lavabos y WiFi.</p>
                </Grid>
                <Grid item 
                        className={matches? classes.frontImage: classes.frontImageMob} lg={4} sm={12}>
                    <img 
                        alt="Sala"
                        src='./nuestroEspacio.jpg' 
                        style={{width: matches? '42em':'100%'}}
                    ></img>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
}

export default Home