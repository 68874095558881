import React from 'react'
import { Grid, Card, makeStyles,
         CardContent, Typography } from '@material-ui/core'

const useStyles = makeStyles({
    card: {
      minWidth: 375,
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
      'font-weight': 'bold',
      color: 'black'
    },
    pos: {
      marginBottom: 12,
    }
});

function Contact() {
    const classes = useStyles();
    //TODO: Paradas de metro cercanas. Como llegar?   
    //      Alquiler de salas
    return <Grid container justify='center' style={{paddingTop: '3em',  paddingBottom: '                                                                                               3em', height: '90%'}}>
        <Card className={classes.card}>
            <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                EMail:
                </Typography>
                <p>eslaroda@gmail.com</p>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                Teléfono:
                </Typography>
                <p>(+34) 675 20 24 87</p>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                Dirección:
                </Typography>
                <p>Passatge Serra i Arola, 15 (08028 Barcelona)</p>
            </CardContent>
        </Card>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2993.8913247457044!2d2.1330311158501982!3d41.3764457792651!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a499b17c361e8b%3A0xd8816befd84113bc!2sEspai%20La%20Roda!5e0!3m2!1ses!2ses!4v1599319520646!5m2!1ses!2ses" width="600" height="450" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
    </Grid>
}

export default Contact